import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import type {BlockProps} from '@/components/brochureV2/primitive/Block/types';
import {
  getButtonTheme,
  type ButtonProps,
} from '@/components/brochureV2/primitive/Button/types';
import Section from '@/components/brochureV2/primitive/Section/Section';
import {AlignmentEnum, ColorSchemeEnum} from '@/enums';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';
import {twMerge} from '@/stylesheets/twMerge';

import type {OutcomesSection} from './types';
import variants, {OutcomesVariant} from './variants';

export {OutcomesVariant};

export interface OutcomesProps extends OutcomesSection {
  id?: string;
  className?: string;
  headingClass?: string;
  showUnderHeadingSpacer?: boolean;
  eyebrowClass?: string;
  spacerOneClass?: string;
  spacerTwoClass?: string;
  gridColOneClass?: string;
  gridColTwoClass?: string;
  customImageClass?: string;
  customSpacer?: boolean;
  blocksClass?: string;
  blockContainerClass?: string;
  blockSize?: BlockProps['size'];
  customButton?: (props: ButtonProps) => JSX.Element;
  sectionName?: string;
  h3CustomSize?: string;
}

/**
 * @deprecated
 */
export default function Outcomes({
  alignment,
  blocks = [],
  className,
  spacerOneClass,
  spacerTwoClass,
  gridColOneClass,
  gridColTwoClass,
  blocksClass,
  blockContainerClass,
  blockSize,
  colorScheme,
  customButton,
  customSpacer,
  customImageClass,
  eyebrowClass,
  h3CustomSize,
  heading,
  headingClass,
  showUnderHeadingSpacer = true,
  image,
  kicker,
  link,
  linkImage,
  outcomesVariant,
  subhead,
  video,
  ...props
}: OutcomesProps) {
  const currentAlignment = alignment || AlignmentEnum.Right;
  const currentScheme = colorScheme || ColorSchemeEnum.Light;
  const currentVariant = outcomesVariant || OutcomesVariant.V01;
  const buttonTheme = getButtonTheme(currentScheme);
  const variantProps = {
    alignment: currentAlignment,
    blocks,
    blocksClass,
    blockContainerClass,
    blockSize,
    buttonTheme,
    customButton,
    h3CustomSize,
    heading,
    headingClass,
    showUnderHeadingSpacer,
    eyebrowClass,
    gridColOneClass,
    gridColTwoClass,
    customSpacer,
    customImageClass,
    image,
    kicker,
    link,
    linkImage,
    subhead,
    video,
  };
  const Component = variants[currentVariant];

  return (
    <Section
      sectionName="Outcomes"
      {...props}
      className={twMerge(sectionSchemeClasses[currentScheme], className)}
    >
      <Spacer size="3xl" className={spacerOneClass} />
      <Component {...variantProps} />
      <Spacer size="3xl" className={spacerTwoClass} />
    </Section>
  );
}
